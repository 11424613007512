import { useEffect, useState } from 'react';
import styled from 'styled-components';
import useInterval from './hooks';

import './App.css';

import WhiteLogo from "./assets/logos/White_Logo.png"
import ColorLogo from "./assets/logos/Full_Color.png"
import WaterIcon from "./assets/img/Water_Full_Color.png"
import SteamIcon from "./assets/img/Steam_Full_Color.png"
import IceIcon from "./assets/img/Ice_Full_Color.png"



function App() {
    const [active, setActive] = useState(1)
    const [navClass, setNavClass] = useState('');
    const [autoRotate, setAutoRotate] = useState(true)

    useInterval(() => {
        updateActive()
    }, autoRotate ? 3500 : null)

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY >= 200) {
                setNavClass('scrolled');
            } else {
                setNavClass('');
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const updateActive = () => {
        switch (active) {
            case 1:
                setActive(2)
                break;
            case 2:
                setActive(3)
                break;
            case 3:
                setActive(1)
                break;
            default:
                setActive(1)
                break;
        }
    }

    const onMottoClick = (id) => {
        setAutoRotate(false)
        setActive(id)
        setTimeout(() => {
            setAutoRotate(true)
        }, 5000)
    }

    const now = new Date();
    const currentYear = now.getFullYear();

  return (
    <div className="App">
        <Content>

        <nav className={navClass}>
            <div>
                <div className='logos'>
                    <img className='white' src={WhiteLogo} height={25} />
                    <img className='color' src={ColorLogo} height={25} />
                </div>
                <a className='button' href='https://app.icewater.money'>
                    Launch App
                </a>
            </div>
        </nav>

        <div className="header">
            <div className='motto'>
                <h1>H2O</h1>
                <h2>
                    <span className={active==1 ? 'active' : ''}
                            onClick={() => { onMottoClick(1) }}>
                            Pure.
                        </span>
                    <span className={active==2 ? 'active' : ''}
                            onClick={() => {onMottoClick(2) }}>
                            Stable.
                        </span> 
                    <span className={active==3 ? 'active' : ''}
                            onClick={() => {onMottoClick(3) }}>
                            Money.
                        </span>
                </h2>
            </div>

            <div className="carousel">
                <div className={active==1 ? 'active' : ''} >
                    <h3>The value of H2O does not depend on fiat currency.</h3>
                </div>
                <div className={active==2 ? 'active' : ''}>
                    <h3>A reliable algorithm keeps H2O stable.</h3>
                </div>
                <div className={active==3 ? 'active' : ''}>
                    <h3>H2O is a fast, reliable way to transfer value.</h3>
                </div>
            </div>

            
        </div>

        <div className="main">
            <div className="section">
                <div className="container tokens">
                    <div className="tml-auto mr-auto col-md-8">
                        <h2 className="title">
                            H2O maintains stability using three tokens
                        </h2>
                    </div>

                    <div className="row token">
                        <div className="left">
                            <img alt="..." className="iceWaterIconSvg" src={WaterIcon} />
                        </div>
                        <div className="right">
                            <h4>H2O</h4>
                            <br/>
                            <div>A "liquid" currency, like your checking account. Use H2O for everyday transactions, but don't hold it for too long because it's subject to a modest rate of inflation.</div>
                        </div>
                    </div>
                    <div className="row token">
                        <div className="left">
                            <img alt="..." className="iceWaterIconSvg" src={IceIcon} />
                        </div>
                        <div className="right">
                            <h4>Ice</h4>
                            <br/>
                            <div>A stable inflation-protected asset, like your savings account. Store your money in ICE if you want to keep it safe.</div>
                        </div>
                    </div>
                    <div className="row token">
                        <div className="left">
                            <img alt="..." className="iceWaterIconSvg" src={SteamIcon} />
                        </div>
                        <div className="right">
                            <h4>Steam</h4>
                            <br/>
                            <div>A volatile asset that represents contributions to the Icewater protocol.</div>
                        </div>
                    </div>

                    <br/><br/><br/><br/><br/>
                </div>
            </div>

            <div className="faq">
                <div className="row">
                        <br/><br/>
                        <h2 className="title text-center maxWidthCenter">Frequently Asked Questions</h2>
                        
                        <h4>What is H2O?</h4>
                        <br/>
                        <h5 className="description">H2O is a cryptocurrency that is designed to maintain price stability independent of any fiat currency.</h5>
                        <br/>

                        <h4>Why do we need a stablecoin?</h4>
                        <br/>
                        <h5 className="description">Cryptocurrencies that have no stabilization mechanism are unsuitable for certain currency functions, such as acting as a unit of account for prices and contracts.</h5>
                        <br/>

                        <h4>Why do we want a stablecoin that isn't pegged to the dollar?</h4>
                        <br/>
                        <h5 className="description">The crypto economy should not be tied to a fiat currency. Yet most stablecoins explicitly tie the value to the US dollar. This creates a dependence on the US Federal Reserve that is not appropriate as the foundation for the new global economy.</h5>
                        <br/>

                        <h4>How do we measure stability if we don't peg to the dollar?</h4>
                        <br/>
                        <h5 className="description">We measure the future value of H2O. If you can maintain a constant future value, you can achieve stability without reference to any external currency or commodity.</h5>
                        <br/>

                        <h4>How do we measure the future price of H2O?</h4>
                        <br/>
                        <h5 className="description">ICE tokens payout H2O over time. Thus, inflation will impact the value of ICE. If we can keep the value of ICE stable relative to H2O, it will stabilize the value of both of them with respect to external assets.</h5>
                        <br/>

                        <h4>How do we correct for inflation and deflation?</h4>
                        <br/>
                        <h5 className="description">If the ICE market predicts inflation, we decrease the supply of H2O. If the ICE market predicts deflation, we increase the supply of H2O.</h5>
                        <br/>

                        <h4>How do we change the supply of H2O?</h4>
                        <br/>
                        <h5 className="description">We mint and burn H2O using auctions. When the supply of H2O is too low, we mint more and auction it off for ETH. When the supply of H2O is too high, we auction off the ETH and burn the H2O we collect.</h5>
                        <br/>

                        <h4>Is Icewater decentralized?</h4>
                        <br/>
                        <h5 className="description">Decentralization is one of our core values. Currently, the protocol is under active development and is fully controlled by members of the core development team. However, we are building it in a manner that will enable it it become fully decentralized.</h5>
                        <br/>

                  
                </div>
                <br/>
            </div> 
        </div>

        <div className='footer'>
            <div>
                <a href='https://twitter.com/icewatermoney'>
                    Twitter
                </a>
                <a href='https://medium.com/icewatermoney'>
                    Medium
                </a>
                <a href='https://discord.gg/yw84JYaXHM'>
                    Discord
                </a>
            </div>
            <div>
                © { currentYear }, IceWater
            </div>
        </div>

        </Content>
    </div>
  );
}

export default App;

const Content = styled.div `
    nav {
        display: block;
        position: fixed;
        top: 0px;
        left: 0px;
        right: 0px;
        z-index: 2;

        > div {
            display: flex;
            justify-content: space-between;
            padding: 20px;
            max-width: 1000px;
            margin: 0px auto;
        }

        transition: background 0.5s;

        .logos {
            position: relative;
            img {
                display: block;
                position: absolute;
                transition: opacity 0.5s;
            }
        }

        &:not(.scrolled) {
            .white {
                opacity: 1;
            }
            .color {
                opacity: 0;
            }
        }

        &.scrolled {
            background: #fff;
            .white {
                opacity: 0;
            }
            .color {
                opacity: 1;
            }
            box-shadow: 0 0px 10px #999;
        }

        @media (max-width: 768px) {
            .logos {
               top: 8px;
            }
        };
    }

    button, a.button {
        font-size: 12px;
        text-transform: uppercase;
        display: block;
        padding: 10px 20px;
        color: #fff;
        background: #656ccc;
        font-weight: bold;
        border: 2px solid #656ccc;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
        text-decoration: none;
    }

    .title {
        font-size: 40px;
        color: #333;
        max-width: 700px;
        margin: 100px auto;
    }

    .header {
        background: #34b291;
        background: linear-gradient(180deg,#34b291,#0a6997 50%,#432b7c);
        background-position: 50%;
        background-size: cover;
        min-height: 100vh;
        max-height: 999px;
        overflow: hidden;
        position: relative;
        width: 100%;
        z-index: 1;
        display: -webkit-flex;
        display: flex;
        flex-direction: column;
        -webkit-align-items: center;
        align-items: center;
        -webkit-justify-content: center;
        justify-content: center;
    }

    .motto {
        position: relative;
        top: -40px;
        
        h1 {
            margin-top: -0px;
            color: #fff;
            font-weight: 600;
            font-size: 140px;
            text-shadow: rgba(0, 0, 0, 0.4) 2px 2px;
        }
        h2 {
            margin-top: 0px;
            font-size: 40px;
            color: #fff;
            text-shadow: rgba(0, 0, 0, 0.4) 2px 2px;
            span {
                margin-right: 15px;
                cursor: pointer;
            }
            .active {
                font-weight: 600;
            }
        }

        @media (max-width: 768px) {
            h1 {
                font-size: 80px;
            }
            h2 {
                font-size: 26px;
            }
        };
    }

    .carousel {
        text-align: center;
        width: 500px;
        display: flex;
        justify-content: center;
        
        > div {
            position: absolute;
            margin: 0px auto;
            color: #fff;
            opacity: 0;
            transition: opacity 0.5s;
            &.active {
                opacity: 1; 
            }
        }

        @media (max-width: 768px) {
            margin-top: 50px;
        };
    }

    .faq {
        font-size: 18px;
        text-align: left;
        color: #66615b;
        background: #eeeeee;
        padding: 20px;

        .row {
            max-width: 700px;
            margin: 0px auto;
        }

        h4 {
            font-size: 22px;
            margin: 30px 0px 0px 0px;
        }
        h5 {
            font-size: 19px;
            margin: 0px 0px 8px 0px;
            line-height: 26px;
        }

        @media (max-width: 768px) {
            h2 {
                font-size: 32px;
                margin: 0px auto 25px auto;
            }
        };
    }

    .row {
        font-size: 18px; 
        padding: 40px 0px; 
        max-width: 750px; 
        margin: 0px auto;
    }

    .tokens {
        @media (max-width: 768px) {
            padding: 20px;
        };
    }

    .token {
        display: flex;
        align-items: flex-start;

        .left {
            width: 100px;
            padding-right: 20px;
            img {
                max-width: 100%;
            }
        }
        .right {
            flex: 1;
            text-align: left;
            h4 {
                margin: 0px;
            }
        }

        @media (max-width: 768px) {
            .left {
                width: 50px;
            }
        };
    }

    .footer {
        padding: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        max-width: 1000px;
        margin: 0px auto;

        a {
            color: #777;
            font-weight: 600;
            text-transform: uppercase; 
            font-family: "Montserrat","Helvetica",Arial,sans-serif;
            text-decoration: none;
            display: inline-block:
            padding: 20px;
            margin-right: 30px;
            font-size: 12px;
        }
    }

    @media (max-width: 768px) {
        
    };

    
`